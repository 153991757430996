import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';

// Config
import siteConfig from '../../config/site';

// Components
import LayoutDefault from '../components/LayoutDefault';
import MuiButton from '../components/MuiButton';
import SEO from '../components/SEO';

const NotFoundPage: React.FunctionComponent<PageProps> = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      padding: '150px 0',
    },
    title: {
      marginBottom: 50,
    },
    intro: {
      marginBottom: 30,
    },
    buttonList: {
      '& > a': {
        marginRight: 30,
      },
    },
  }));

  const classes = useStyles(props);

  return (
    <main>
      <SEO
        isNoIndex
        description={siteConfig.siteDescription}
        title={`500 - ${siteConfig.siteTitle}`}
      />

      <LayoutDefault>
        <Container maxWidth="xl">
          <Box className={classes.root}>
            <Typography variant="h1" className={classes.title}>
              500
            </Typography>
            <Typography className={classes.intro}>
              There was a problem loading this page. Please try again later
            </Typography>

            <div className={classes.buttonList}>
              <Link aria-label="Back to home" to="/">
                <MuiButton aria-label="Back to home" name="homepage" variant="contained">
                  Back to home
                </MuiButton>
              </Link>
            </div>
          </Box>
        </Container>
      </LayoutDefault>
    </main>
  );
};

interface PageProps {
  pageContext: any;
}

export default NotFoundPage;
